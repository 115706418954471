import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Faq from "../components/faq/faq";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import News from "../components/news/news";
import ContentWithImage from "../components/products/contentWithImage/contentWithImage";
import Hero from "../components/products/hero/hero";
import Percent from "../components/products/percent/percent";
import Seo from "../components/seo";
import DemoButton from '../components/demoButton/demoButton';
import Testimonial from "../components/products/testimonial/testimonial";
import Partners from "../components/products/partners/partners";
import ContactForm from "../components/products/contactForm/contactForm";
import Percents from '../components/products/percents/percents';


// markup
const MarketingPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const excerpt = `Our powerful AI integration automates coupons and offers, delivering them
    seamlessly to your customers through SMS and Email Marketing without any
    manual intervention, ensuring a hassle-free experience.`;

  const heroBlock = {
    title: <>All the Restaurant <span style={{ backgroundImage: 'linear-gradient(92deg, #FF6B01 60.62%, #B02C01 97.16%)' }}>Marketing</span> Tools to<br /> Grow You Audience.</>,
    desktop: {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="marketing"
          src="../images/marketing/hero.png"
          className="mw-100"
        />
      ),
      smallImageStyle: {
        bottom: '32px',
        left: '160px',
        maxWidth: '490px',
      },
      smallImage: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="marketing"
          src="../images/marketing/hero-floating.png"
          className="mw-100"
        />
      )
    },
    mobile: {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="marketing"
          src="../images/marketing/hero-mobile.png"
          className="mw-100"
        />
      ),
      smallImageStyle: {},
      smallImage: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="marketing"
          src="../images/marketing/hero-floating-mobile.png"
          style={{ maxWidth: '300px' }}
        />
      )
    },
    description: <>Our powerful AI integration automates coupons and offers, delivering them<br className="d-none d-lg-inline-block" />
      seamlessly to your customers through SMS and Email Marketing without any<br className="d-none d-lg-inline-block" />
      manual intervention, ensuring a hassle-free experience.</>,
  }

  const percent1Block = {
    vertical: false,
    percent: <><span style={{ backgroundImage: 'linear-gradient(125deg, #27FFFF 29.34%, #000 144.39%)' }}>98%</span></>,
    description: <>Open rate for text messages</>
  }

  const textCompaigns = {
    reverse: false,
    badge: <div className="blue-badge">
      <span style={{ fontSize: '12px' }}>AI</span> powered
    </div>,
    title: <><span style={{ backgroundImage: 'linear-gradient(90deg, #FF6C02 0.1%, #D84C02 62.75%)' }}>Automated Text Campaigns</span></>,
    description: <>Boost <span style={{ color: '#FF8A00' }}>customer engagement</span> and sales<br /> through targeted AI text campaigns.</>,
    button: <DemoButton scrollToForm={scrollToForm} classes={[]}>Schedule a DEMO</DemoButton>,
    items: [
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="coupons-and-offers"
          src="../images/marketing/coupons_and_offers.png"
          className="mw-100"
        />,
        title: <>Text Coupons<br /> & Offers</>,
        description: false,
      },
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="promote-new-items"
          src="../images/marketing/promote.png"
          className="mw-100"
        />,
        title: <>Promote New<br /> Items</>,
        description: false,
      },
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="loyalty-programm"
          src="../images/marketing/loyalty.png"
          className="mw-100"
        />,
        title: <>Mobile Loyalty<br /> Program</>,
        description: false,
      },
    ],
    image: {
      desktop: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="text-compaigns"
          src="../images/marketing/text-compaigns.png"
          className="mw-100"
        />
      ),
      mobile: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="text-compaigns"
          src="../images/marketing/text-compaigns-mobile.png"
          className="mw-100"
        />
      ),
    },
  }

  const percent2Block = {
    vertical: false,
    percent: <><span style={{ backgroundImage: 'linear-gradient(125deg, #27FFFF 29.34%, #000 144.39%)' }}>17%</span></>,
    description: <>INCREASE IN AVERAGE ORDER SIZE FROM EMAIL OFFERS</>
  }

  const emailCompaigns = {
    reverse: true,
    badge: <div className="blue-badge">
      <span style={{ fontSize: '12px' }}>AI</span> powered
    </div>,
    title: <><span style={{ backgroundImage: 'linear-gradient(90deg, #FF6C02 0.25%, #D84C02 73.53%)' }}>Automated Email Campaigns</span></>,
    description: <>Boost customer engagement and sales with<br /> targeted AI email campaigns.</>,
    button: <DemoButton scrollToForm={scrollToForm} classes={[]}>Schedule a DEMO</DemoButton>,
    items: [
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="coupons-and-offers"
          src="../images/marketing/coupons_and_offers.png"
          className="mw-100"
        />,
        title: <>Text Coupons<br /> & Offers</>,
        description: false,
      },
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="promote-new-items"
          src="../images/marketing/promote.png"
          className="mw-100"
        />,
        title: <>Promote New<br /> Items</>,
        description: false,
      },
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="loyalty-programm"
          src="../images/marketing/loyalty.png"
          className="mw-100"
        />,
        title: <>Mobile Loyalty<br /> Program</>,
        description: false,
      },
    ],
    image: {
      desktop: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="email-compaigns"
          src="../images/marketing/email-compaigns.png"
          className="mw-100"
        />
      ),
      mobile: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="email-compaigns"
          src="../images/marketing/email-compaigns-mobile.png"
          className="mw-100"
        />
      ),
    },
  }

  const percent3Block = {
    vertical: false,
    percent: <><span style={{ backgroundImage: 'linear-gradient(125deg, #27FFFF 29.34%, #000 144.39%)' }}>56%</span></>,
    description: <>TRUST PRINT MORE THAN OTHER FORMS OF ADVERTISING</>
  }

  const marketingMaterials = {
    reverse: false,
    badge: false,
    title: <>
      Printed<br /> <span style={{ backgroundImage: 'linear-gradient(91deg, #FF6C03 -2.79%, #D94D03 75.41%)' }}>Marketing Materials</span>
    </>,
    description: <>
      Use branded collateral to educate your<br /> guests on how to order directly via your<br /> custom Orders.co website.
      {/* Elevate Restaurant Marketing with<br /> Orders.co - Includes<span style={{ color: '#FF8A00' }}> Exclusive Materials!</span> */}
    </>,
    button: <DemoButton scrollToForm={scrollToForm} classes={[]}>Schedule a DEMO</DemoButton>,
    items: [
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="qr-cards-bonus"
          src="../images/marketing/qr_cards.png"
          className="mw-100"
        />,
        title: <>Printed QR<br /> Cards Bonus</>,
        description: <>250 Units<br /> signup bonus</>,
      },
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="printed-menu"
          src="../images/marketing/printed_menu.png"
          className="mw-100"
        />,
        title: <>Half Page<br /> Printed Menu</>,
        description: <>500 Units<br /> signup bonus</>,
      },
    ],
    image: {
      desktop: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="marketing-materials"
          src="../images/marketing/marketing-materials.png"
          className="mw-100"
          height={500}
        // style={{maxHeight: '500px'}}
        />
      ),
      mobile: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="marketing-materials"
          src="../images/marketing/marketing-materials-mobile.png"
          className="mw-100"
        />
      ),
    },
  }

  const testimonial = {
    title: <>
      <span style={{ backgroundImage: 'linear-gradient(90deg, #FE6B04 -1.33%, #EA5B04 45.68%)' }}>Client </span> Experiences
    </>,
    place: <>Latha African Style BBQ</>,
    person: <>Maria Albegiani - Owner</>,
    review: <>
      We've been working with Kevin [account manager], one-on-one, making sure we do things the right way.
      I can't complain, things are doing better, we are doing better. It's 75% improvement of what we had before.
    </>,
    button: <Link to={'/customer-stories/'} className='more-link'>Explore more stories</Link>,
    image: {
      desktop: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="Latha-African-Style-BBQ"
          src="../images/marketing/testimonial.png"
          className="mw-100"
        />
      ),
      mobile: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="Latha-African-Style-BBQ"
          src="../images/marketing/testimonial.png"
          className="mw-100"
        />
      ),
    },
  }

  const questions = [
    {
      question: `What is an automated campaign?`,
      answer: `Automated Email and Text (SMS) campaigns are automatically sent to your customers based on a predefined system of actions.
        For example - Discount offers sent if the customer has not purchases anything in the last 14 days.`,
    },
    {
      question: `Who creates & sends the automated campaigns?`,
      answer: `Our proprietary AI system generates and delivers all Email and Text messages without any need for manual assistance.`,
    },
    {
      question: `How do I get my print materials?`,
      answer: `Print menus and QR cards are included free of charge in the welcome kit for all Pro and Growth packages.`,
    },
    {
      question: `How do I get more print materials?`,
      answer: `You can order more print your own Menus and Cards from Orders.co, or print your own with the files provided in your welcome kit.`,
    },
  ];
  const contactBlock = {
    // title: <>Take The <span style={{ backgroundImage: 'linear-gradient(90deg, #FE6B01 27.81%, #B12D01 52.37%)' }}>First Step</span> Towards A More<br /> Powerful Ordering Solution</>,
  }

  return (
    <>
      <Seo
        title={"Marketing Solutions (Powered By AI)"}
        description={excerpt}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Hero block={heroBlock} />

        <ContentWithImage block={textCompaigns} />
        <Percent block={percent1Block} />
        <ContentWithImage block={emailCompaigns} />
        <Percent block={percent2Block} />
        <ContentWithImage block={marketingMaterials} />
        <Percent block={percent3Block} />
        <Testimonial block={testimonial} />
        <Partners />
        <News />
        <Faq items={questions} />
        <div ref={formRef}>
          <ContactForm block={contactBlock} />
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "marketing-solutions" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "menu-management"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default MarketingPage;
